<template lang="pug">
  jumbotron(title="" small)
    template(v-slot:content)
      app-button(color="primary" @click.native="importAccounts" :disabled="loading").button Import
</template>

<script>
import permsMixin from '@/mixins/perms.mixin'
import appButton from '@/components/global/actions/BaseButton.vue'

export default {
  name: 'CbrAccountsActions',

  inject: ['svc'],

  mixins: [permsMixin],

  props: {
    loading: {type: Boolean, default: false},
    disabled: {type: Boolean, default: false},
    activeItems: {type: Array, required: true}
  },

  computed: {
    isDisabled() {
      return this.loading || this.disabled;
    }
  },

  methods: {
    async importAccounts() {
      try {
        let ids = this.activeItems.map(item => item.id)
        await this.svc.importAccounts(ids)
        this.$notify({text: 'Import started', type: 'success'})
      } catch (error) {
        this.processError(error, {redirectName: this.$ROUTER_NAMES.LOGIN_CRM})
      }
    }
  },

  components: {
    appButton,
    jumbotron: () => import('@/components/global/fields/Jumbotron.vue')
  }
}
</script>

<style lang="scss">
.button {
  text-transform: uppercase;
}
</style>
